import React, { useState, useEffect, useContext, useRef } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
// import MovingText from 'react-moving-text'; -package-remove

import { makeStyles } from '@mui/styles';
import { Box, Typography, Container, Grid, Paper, CircularProgress } from '@mui/material';

// layout
import StartLayout from '/layouts/StartLayout/start';

// store
import { GlobalContext } from '/context';

// utils
import useWindowSize from '/utils/useWindowSize';
import LogoImage from '/public/start/Biglogo.svg';
import Liner from '/public/start/liner.svg';

import { getCovers, addCover, deleteCover } from '/context/actions/cover';

const useStyles = makeStyles((props) => ({
    container: {
        backgroundColor: '#FFFFFF',
        borderRadius: 5,
        padding: 26,
        margin: 0,
    },
    header: {
        height: 30,
    },
    logoBox: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        // marginTop: 40,
    },
    blueBox: {
        background:
            'transparent linear-gradient(90deg, #2E3192 0%, #0061B0 100%) 0% 0% no-repeat padding-box',
        width: '86%',
        margin: '26px auto',
        borderRadius: 30,
    },
    emptyBox: {
        width: '100%',
        height: '100%',
    },
    startContainer: (props) => {
        return {
            display: 'flex',
            width: '100%',
            height: props.cover ? '100%' : '50%',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            backgroundSize: 'cover',
            borderRadius: 10,
            flexDirection: 'row',
            paddingTop: 25,
        };
    },

    cardBox: (props) => {
        return {
            width: props.windowsize > 700 ? 190 : 134,
            height: props.windowsize > 700 ? 190 : 134,
            borderColor: 'rgba(255, 255, 255, 0.26)',
            borderWidth: 2,
            borderRadius: 30,
            margin: props.windowsize > 700 ? 30 : '0px 0px 0px 30px',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            backdropFilter: 'blur(2px)',
            background: 'rgba(255, 255, 255, 0.48)',
            transition:
                'transform 0.3s ease, backdrop-filter 0.3s ease, background 0.3s ease, border-color 0.3s ease', // Анимацын тохиргоо
            '&:hover': {
                background: 'rgba(255, 255, 255, 0.02)',
                borderColor: 'rgba(255, 255, 255, 0.09)',
                cursor: 'pointer',
                // backdropFilter: 'blur(4px)',
                transform: 'scale(1.1)',
            },
        };
    },
    cardBlur: {
        width: 190,
        height: 190,
        filter: 'blur(1px)',
        background: 'rgba(255, 255, 255, 0.58)',
        backdropFilter: 'blur(4px)',
    },

    logo: {
        width: 106,
        height: 106,
        display: 'flex',
        justifyContent: 'center',
        // marginBottom: '10px',
    },
    text: {
        color: 'white',
        width: '150px',
        marginTop: '10px',
    },
    title: {
        // height: 138,
        display: 'flex',
        color: 'white !important',
        alignItems: 'center',
        margin: 'auto !important',
        width: 450,
        textAlign: 'center',
        paddingBottom: 45,
        // padding: '60px 0px 30px 0px',
    },
}));

import img from '/public/start/background.png';
import one from '/public/start/logo.svg';
import two from '/public/start/training.svg';
import three from '/public/start/time.svg';

const UpLogo = ({ ...props }) => {
    const { width } = useWindowSize();
    const classec = useStyles({ windowsize: width });

    return (
        <Box className={`${classec.logoBox} ${props.className || ''}`}>
            <div style={{ marginBottom: props.marginbottom }}>
                <Image
                    {...props}
                    src={LogoImage}
                    width={130}
                    height={130}
                    alt="Анагаах Ухааны Хүрээлэн"
                    priority
                    fetchpriority="high"
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography
                    variant="h6"
                    style={{
                        color: '#2E3192',
                        whiteSpace: 'nowrap',
                        marginTop: 20,
                        fontSize: 14,
                    }}
                >
                    Т.ШАГДАРСҮРЭНГИЙН НЭРЭМЖИТ
                </Typography>
                <Typography
                    variant="h3"
                    style={{
                        color: '#2E3192',
                        lineHeight: props.text2Height,
                        fontWeight: 'bold',
                        marginTop: 5,
                        fontSize: 16,
                    }}
                >
                    АНАГААХ УХААНЫ ХҮРЭЭЛЭН
                </Typography>
            </div>
            <div style={{ height: 0, marginTop: 10 }}>
                <Image
                    {...props}
                    src={Liner}
                    width={290}
                    height={15}
                    alt="liner"
                    priority
                    fetchpriority="high"
                />
            </div>
        </Box>
    );
};

export default function StartPage() {
    const { width } = useWindowSize();

    const sliderHeight = width < 600 ? 200 : 400;
    const { state } = useContext(GlobalContext);
    const [cover, setCover] = useState(null);
    const [page, setPage] = useState(1);
    const [shape, setShape] = useState([]);
    const routers = ['home', 'training', 'booking'];
    const timerRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);

    // const router = useRouter();
    // const { asPath, pathname, query } = router;
    // console.log('Current path:', asPath); // Full path with query string
    // console.log('Pathnamedsds:', pathname); // Path without query string
    // console.log('Query:', query);

    useEffect(() => {
        getCovers('?page=' + page).then((result) => {
            const newShape = [];
            if (result.list) {
                let newCover = null;
                let sorted = result.list.sort((a, b) => a.pos - b.pos);
                sorted.forEach((item) => {
                    if (item.is_cover) {
                        newCover = item;
                    } else {
                        newShape.push(item);
                    }
                });
                setIsLoading(false);
                setCover(result.list[0]);
            }

            setShape(newShape);
        });
    }, []);

    const classes = useStyles({ windowsize: width, cover });

    if (isLoading) {
        return (
            <Box className="absolute h-screen inset-0 flex justify-center items-center bg-white text-lg z-50">
                <Typography variant="h4">Loading...</Typography>
            </Box>
        );
    }

    return (
        <div className="h-screen">
            <Box
                fullwidth="true"
                maxHeight="100vh"
                style={{
                    backgroundImage: cover?.image ? `url('${cover.image}')` : 'none',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: '100vh',
                }}
            >
                {!cover && (
                    <>
                        <Box className={classes.header}></Box>
                        <UpLogo classes={classes} />
                    </>
                )}

                <div className={!cover ? classes.blueBox : classes.emptyBox}>
                    <div className={classes.startContainer}>
                        {shape.length > 0 ? (
                            shape.map((item, i) => (
                                <Link
                                    key={i}
                                    href={`/${item.path}`}
                                    rel="noreferrer"
                                    className={classes.cardBox}
                                >
                                    <Box className={classes.logo}>
                                        <Image
                                            loader={() => item.image}
                                            src={item.image}
                                            width={106}
                                            height={106}
                                            quality={100}
                                            alt=""
                                            unoptimized
                                            fetchpriority="high"
                                            priority
                                        />
                                    </Box>
                                    <Typography variant="h4" className={classes.text}>
                                        {item.title}
                                    </Typography>
                                </Link>
                            ))
                        ) : (
                            <>
                                <Link className={classes.cardBox} href={'/home'} rel="noreferrer">
                                    <Box className={classes.logo}>
                                        <Image
                                            loader={() => one}
                                            src={one}
                                            width={106}
                                            height={106}
                                            quality={100}
                                            alt=""
                                            unoptimized
                                            fetchpriority="high"
                                            priority
                                        />
                                    </Box>
                                    {width > 700 && (
                                        <Typography variant="h4" className={classes.text}>
                                            Байгууллагын веб сайт
                                        </Typography>
                                    )}
                                </Link>
                                <Link
                                    className={classes.cardBox}
                                    target="_blank"
                                    href={'/booking'}
                                    rel="noreferrer"
                                >
                                    <Box className={classes.logo}>
                                        <Image
                                            loader={() => two}
                                            src={two}
                                            width={106}
                                            height={106}
                                            quality={100}
                                            objectFit="contain"
                                            alt=""
                                            fetchpriority="high"
                                            priority
                                            unoptimized
                                        />
                                    </Box>
                                    {width > 700 && (
                                        <Typography variant="h4" className={classes.text}>
                                            Эмчийн үзлэгийн цаг захиалах{' '}
                                        </Typography>
                                    )}
                                </Link>

                                <Link
                                    className={classes.cardBox}
                                    href={'/training'}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Box className={classes.logo}>
                                        <Image
                                            loader={() => three}
                                            src={three}
                                            width={106}
                                            height={106}
                                            quality={100}
                                            objectFit="contain"
                                            alt=""
                                            unoptimized
                                            fetchpriority="high"
                                            priority
                                        />
                                    </Box>
                                    {width > 700 && (
                                        <Typography variant="h4" className={classes.text}>
                                            Эмч, мэргэжилтэнд зориулсан сургалт
                                        </Typography>
                                    )}
                                </Link>
                            </>
                        )}
                    </div>
                    {!cover && (
                        <Typography
                            variant={width > 700 ? 'h3' : 'subtitle3'}
                            className={classes.title}
                        >
                            Манай байгууллага 60 жилийн хугацаанд тасралтгүй үйл ажиллагаа явуулж
                            байна
                        </Typography>
                    )}
                </div>
            </Box>
        </div>
    );
}

// StartPage.defaultProps = {
//     page: 'start',
// };

StartPage.Layout = StartLayout;
