import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

// store
import { GlobalContext } from '/context';
import useWindowSize from '/utils/useWindowSize';

// actions
import { getMenus } from '/context/actions/menu';

const useStyles = makeStyles(() => ({
    root: (props) => {
        return {
            width: props.windowsize > 700 ? '100%' : '100vh',
            height: '100vh',
            background: '#F3F3F3',
        };
    },
}));

export default function StartLayout({ children }) {
    const { width } = useWindowSize();
    const classes = useStyles({ windowsize: width });
    const { state, dispatch } = useContext(GlobalContext);

    return (
        <div>
            <main>
                <Box className={classes.root}>{children}</Box>
            </main>
        </div>
    );
}
