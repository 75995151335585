import { useEffect, useState } from 'react';

export default function useWindowSize(props) {
    // const isSSR = process.browser ? true : false;

    const [os, setOs] = useState('');
    const [windowsize, setWindowSize] = useState({
        width: 1200,
        height: 800,
    });

    useEffect(() => {
        if (typeof window !== 'undefined') {
            function handleResize() {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }

            const userAgent = window.navigator.userAgent.toLowerCase();
            // const platform = window.navigator.userAgentData.platform.toLowerCase();
            if (userAgent.includes('win')) setOs('Windows');
            else if (userAgent.includes('mac')) setOs('MacOS');
            else if (userAgent.includes('linux')) setOs('Linux');
            else if (userAgent.includes('android')) setOs('Android');
            else if (userAgent.includes('iphone')) setOs('iOS');
            else setOs('Unknown');

            window.addEventListener('resize', handleResize);

            handleResize();

            return () => window.removeEventListener('resize', handleResize);
        }
    }, []);

    return { ...windowsize, os: os };
}
